import React from "react";
import "../styles/Experiences.css";
import ExpCard from "./ExpCard";
import Stock from "../assets/experience/stock.svg";
import Shockdrop from "../assets/experience/shockdrop.svg";
import Delivery from "../assets/experience/delivery.svg";
import Bricks from "../assets/experience/bricks.svg";
import Account from "../assets/experience/account.svg";
import Team from "../assets/experience/team.svg";

function Experiences() {
  const items = [
    {
      footer: (
        <p>
          EXACT STOCK <br />
          CHECKER
        </p>
      ),
      image: Stock,
    },
    {
      footer: (
        <p>
          SHOCKDROPS <br />
          NOTIFICATIONS
        </p>
      ),
      image: Shockdrop,
    },
    {
      footer: (
        <p>
          ORDER TRACKER <br />
          IN-APP
        </p>
      ),
      image: Delivery,
    },
    {
      footer: (
        <p>
          CANCEL BRICKS <br />
          WITH ONE CLICK
        </p>
      ),
      image: Bricks,
    },
    {
      footer: (
        <p>
          ACCOUNT <br />
          CHECKER & MANAGER
        </p>
      ),
      image: Account,
    },
    {
      footer: (
        <p>
          PASSIONATE <br />
          SUPPORT TEAM
        </p>
      ),
      image: Team,
    },
  ];

  return (
    <div className="experience">
      <div className="experiences-container">
        {items.map((item, index) => (
          <ExpCard key={index} image={item.image} footer={item.footer} />
        ))}
      </div>
    </div>
  );
}

export default Experiences;
