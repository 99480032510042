import React, { useState, useEffect } from "react";
import "../styles/Roadmap.css";
import Step1 from "../assets/roadmap/roadmap_1.svg";
import Step2 from "../assets/roadmap/roadmap_2.svg";
import Step3 from "../assets/roadmap/roadmap_3.svg";

function Roadmap() {
  const [activeMonth, setActiveMonth] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setMonth = (month) => {
    if (activeMonth === month) {
      setActiveMonth(null);
    } else {
      setActiveMonth(month);
    }
  };

  return (
    <div className="roadmap">
      {windowWidth > 1000 && (
        <>
          <div
            className="roadmap-box"
            onMouseEnter={() => {
              setMonth("Step1");
            }}
            onMouseLeave={() => {
              setMonth(null);
            }}
          >
            <div className="roadmap-container">
              <div className="roadmap-heading">Step 1</div>
              <div
                className={`roadmap-image ${
                  activeMonth === "Step1" ? "slide-in-from-left" : ""
                }`}
                style={{ display: activeMonth !== "Step1" ? "none" : "" }}
              >
                <img src={Step1} alt="" />
              </div>
              <div
                className={`roadmap-text ${
                  activeMonth === "Step1" ? "slide-in-from-left" : ""
                }`}
                style={{
                  flex: activeMonth !== "Step1" ? "1" : "",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {activeMonth === "Step1" ? (
                  "FULLY REWRITE OF THE IOS APP WITH BUILT IN FCFS MONITOR"
                ) : (
                  <p className="custom-font">
                    WHAT'S <br />
                    COMING?
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            className="roadmap-box"
            onMouseEnter={() => setMonth("Step2")}
            onMouseLeave={() => setMonth(null)}
          >
            <div className="roadmap-container">
              <div className="roadmap-heading">Step 2</div>
              <div
                className={`roadmap-image ${
                  activeMonth === "Step2" ? "slide-in-from-top" : ""
                }`}
                style={{ display: activeMonth !== "Step2" ? "none" : "" }}
              >
                <img src={Step2} alt="" />
              </div>
              <div
                className={`roadmap-text ${
                  activeMonth === "Step2" ? "slide-in-from-top" : ""
                }`}
                style={{
                  flex: activeMonth !== "Step2" ? "1" : "",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {activeMonth === "Step2" ? (
                  "GRAPHIC REWORK OF THE CLI BOT DISPLAYING MORE INFORMATION"
                ) : (
                  <p className="custom-font">
                    WHAT'S <br />
                    COMING?
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            className="roadmap-box"
            onMouseEnter={() => setMonth("Step3")}
            onMouseLeave={() => setMonth(null)}
          >
            <div className="roadmap-container">
              <div className="roadmap-heading">Step 3</div>
              <div
                className={`roadmap-image ${
                  activeMonth === "Step3" ? "slide-in-from-right" : ""
                }`}
                style={{ display: activeMonth !== "Step3" ? "none" : "" }}
              >
                <img src={Step3} alt="" />
              </div>
              <div
                className={`roadmap-text ${
                  activeMonth === "Step3" ? "slide-in-from-right" : ""
                }`}
                style={{
                  flex: activeMonth !== "Step3" ? "1" : "",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {activeMonth === "Step3" ? (
                  "ADAPT THE CLI TO BE FULLY SET-UP FROM REMOTE WITH APP & WEBUI"
                ) : (
                  <p className="custom-font">
                    WHAT'S <br />
                    COMING?
                  </p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {windowWidth <= 1000 && (
        <div className="roadmap-box">
          <div className="roadmap-container">
            <div className="roadmap-heading">roadmap</div>
            {activeMonth && (
              <div
                className={`roadmap-image ${
                  activeMonth === "Step1" ? "slide-in-from-left" : ""
                }${activeMonth === "Step2" ? "slide-in-from-top" : ""}${
                  activeMonth === "Step3" ? "slide-in-from-right" : ""
                }`}
              >
                {activeMonth === "Step1" && <img src={Step1} alt="" />}
                {activeMonth === "Step2" && <img src={Step2} alt="" />}
                {activeMonth === "Step3" && <img src={Step3} alt="" />}
              </div>
            )}
            <div
              className={`roadmap-text ${!activeMonth ? "custom-font" : ""} ${
                activeMonth === "Step1" ? "slide-in-from-left" : ""
              }${activeMonth === "Step2" ? "slide-in-from-top" : ""}${
                activeMonth === "Step3" ? "slide-in-from-right" : ""
              }`}
            >
              {!activeMonth ? (
                <p className="custom-font">
                  WHAT'S <br />
                  COMING?
                </p>
              ) : activeMonth === "Step1" ? (
                "FULLY REWRITE OF THE IOS APP WITH BUILT IN FCFS MONITOR"
              ) : activeMonth === "Step2" ? (
                "GRAPHIC REWORK OF THE CLI BOT DISPLAYING MORE INFORMATION"
              ) : (
                "ADAPT THE CLI TO BE FULLY SET-UP FROM REMOTE WITH APP & WEBUI"
              )}
            </div>

            <div className="months">
              <button
                className={`s1 ${activeMonth === "Step1" ? "selected" : ""}`}
                onClick={() => setMonth("Step1")}
              >
                1
              </button>
              <button
                className={`s2 ${activeMonth === "Step2" ? "selected" : ""}`}
                onClick={() => setMonth("Step2")}
              >
                2
              </button>
              <button
                className={`s3 ${activeMonth === "Step3" ? "selected" : ""}`}
                onClick={() => setMonth("Step3")}
              >
                3
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Roadmap;
