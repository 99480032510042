import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Platform.css";
import Cli from "../assets/platforms/cli.png";
import WebApp from "../assets/platforms/web_app.jpeg";
import App from "../assets/platforms/app.png";

function Platform() {
  const innerLeft = "three unique ways to interact with your tasks";
  const innerRight = "you won't miss any shockdrop";
  const images = [WebApp, App, Cli];
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    centerMode: true,
    centerPadding: "10%",
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
  };

  const handleMouseDown = () => {
    setIsGrabbing(true);
  };

  const handleMouseUp = () => {
    setIsGrabbing(false);
  };

  return (
    <div className="platform">
      {windowWidth >= 650 ? (
        <>
          <div className="main-container">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className={`slide-image ${isGrabbing ? "grabbing" : ""} ${
                      index === 0 || index === 2 ? "with-border" : ""
                    }`}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                  />
                </div>
              ))}
            </Slider>
            <div className="inner-rectangle left">
              <p className="corner-text">{innerLeft}</p>
            </div>
            <div className="inner-rectangle right">
              <p className="corner-text">{innerRight}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="new-set">
            <p className="new-text">{innerLeft}</p>
          </div>
          <div className="main-container">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className={`slide-image ${isGrabbing ? "grabbing" : ""} ${
                      index === 0 || index === 2 ? "with-border" : ""
                    }`}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="new-set">
            <p className="new-text">{innerRight}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default Platform;
