import React, { useState } from "react";
import "../styles/Map.css";
import Eu from "../assets/flags/EU.png";
import EuHover from "../assets/flags/EU_hover.png";
import Gb from "../assets/flags/GB.png";
import GbHover from "../assets/flags/GB_hover.png";
import Us from "../assets/flags/US.png";
import Ushover from "../assets/flags/US_hover.png";
import Map from "../assets/map.svg";

function Info() {
  const regionsHeading = "supported regions";
  const regionsText = (
    <p>
      Taranis is currently available in: <br />
      Europe, Great Britain and United States
    </p>
  );
  const [activeFlag, setActiveFlag] = useState(null);
  const [hoveredFlag, setHoveredFlag] = useState(null);

  const handleFlagClick = (flag) => {
    if (activeFlag === flag) {
      setActiveFlag(null);
    } else {
      setActiveFlag(flag);
    }
  };

  const handleFlagHover = (flag) => {
    setHoveredFlag(flag);
  };

  return (
    <div className="map">
      <div className="left-side">
        <div className="regions-container">
          <div className="flags">
            <img
              src={hoveredFlag === "EU" || activeFlag === "EU" ? EuHover : Eu}
              alt=""
              onClick={() => handleFlagClick("EU")}
              onMouseOver={() => handleFlagHover("EU")}
              onMouseOut={() => handleFlagHover(null)}
            />
            <img
              src={hoveredFlag === "GB" || activeFlag === "GB" ? GbHover : Gb}
              alt=""
              onClick={() => handleFlagClick("GB")}
              onMouseOver={() => handleFlagHover("GB")}
              onMouseOut={() => handleFlagHover(null)}
            />
            <img
              src={hoveredFlag === "US" || activeFlag === "US" ? Ushover : Us}
              alt=""
              onClick={() => handleFlagClick("US")}
              onMouseOver={() => handleFlagHover("US")}
              onMouseOut={() => handleFlagHover(null)}
            />
          </div>
          <div
            className={`world-map ${activeFlag === "US" ? "zoom-us" : ""} ${
              activeFlag === "EU" ? "zoom-eu" : ""
            } ${activeFlag === "GB" ? "zoom-gb" : ""}`}
            style={{ backgroundImage: `url(${Map})` }}
          ></div>
        </div>
      </div>
      <div className="right-side">
        <div className="regions">
          <div className="regions-content">
            <div className="region-heading">{regionsHeading}</div>
            <div className="region-text">{regionsText}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Info;
