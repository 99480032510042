import React from "react";
import "../styles/Features.css";
import Card from "./Card";
import cardAnimationRequest from "../assets/animations/request.json";
import cardAnimationApp from "../assets/animations/app_entry.json";
import cardAnimationRemote from "../assets/animations/remote.json";
import Experience from "./Experiences";

function Features() {
  const items = [
    {
      id: 1,
      heading: <p>FASTEST FCFS MODULE</p>,
      image: cardAnimationRequest,
      footer: "request based snkrs/nike module",
    },
    {
      id: 2,
      heading: <p>UNIQUE IN THE GAME</p>,
      image: cardAnimationApp,
      footer: "app entry mode on snkrs",
    },
    {
      id: 3,
      heading: <p>ANYTIME & EVERYWHERE</p>,
      image: cardAnimationRemote,
      footer: "manage your tasks from the app & webui",
    },
  ];

  return (
    <div className="features">
      <div className="features-heading">
        cutting-edge features <br />
        for a smooth botting experience
      </div>
      <div className="features-text">
        Discover all the features we offer, and what makes us the bot you should
        choose for running Nike & SNKRS
      </div>
      <div className="grid-container">
        {items.map((item) => (
          <Card
            key={item.id}
            id={item.id}
            heading={item.heading}
            image={item.image}
            footer={item.footer}
          />
        ))}
      </div>
      <Experience />
    </div>
  );
}

export default Features;
