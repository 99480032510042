import React, { useState, useEffect, useCallback } from "react";
import "../styles/Successes.css";

function Successes() {
  const [backgroundPosition, setBackgroundPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    e.preventDefault();
    setDragging(true);
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (dragging) {
        const offsetX = e.clientX - dragStart.x;
        const offsetY = e.clientY - dragStart.y;

        setBackgroundPosition((prev) => ({
          x: prev.x + offsetX,
          y: prev.y + offsetY,
        }));

        setDragStart({ x: e.clientX, y: e.clientY });
      }
    },
    [dragging, dragStart, setBackgroundPosition]
  );

  const handleTouchStart = (e) => {
    setDragging(true);
    setDragStart({ x: e.touches[0].clientX, y: e.touches[0].clientY });
  };

  const handleTouchMove = useCallback(
    (e) => {
      if (dragging) {
        const offsetX = e.touches[0].clientX - dragStart.x;
        const offsetY = e.touches[0].clientY - dragStart.y;

        setBackgroundPosition((prev) => ({
          x: prev.x + offsetX,
          y: prev.y + offsetY,
        }));

        setDragStart({ x: e.touches[0].clientX, y: e.touches[0].clientY });
      }
    },
    [dragging, dragStart, setBackgroundPosition]
  );

  useEffect(() => {
    const handleTouchEnd = () => {
      setDragging(false);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [handleMouseMove, handleTouchMove]);

  return (
    <div
      className="successes"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      style={{
        backgroundPosition: `${backgroundPosition.x}px ${backgroundPosition.y}px`,
        cursor: dragging ? "grabbing" : "grab",
      }}
    >
      <div className="blur-border top"></div>
      <div className="blur-border bottom"></div>
      successes
    </div>
  );
}

export default Successes;
