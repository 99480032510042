import React from "react";
import "../styles/Menu.css";

function Menu({ onPageClick, currentPage, menuHeading }) {
  return (
    <div className="bottom-menu">
      <div className="menu-heading">{menuHeading}</div>
      <div className="menu-sections">
        <button
          className={`circular-button home ${
            currentPage === 1 ? "active-page" : ""
          }`}
          onClick={() => onPageClick(1)}
        ></button>
        <button
          className={`circular-button feat ${
            currentPage === 2 ? "active-page" : ""
          }`}
          onClick={() => onPageClick(2)}
        ></button>
        <button
          className={`circular-button plat ${
            currentPage === 3 ? "active-page" : ""
          }`}
          onClick={() => onPageClick(3)}
        ></button>
        <button
          className={`circular-button maps ${
            currentPage === 4 ? "active-page" : ""
          }`}
          onClick={() => onPageClick(4)}
        ></button>
        <button
          className={`circular-button road ${
            currentPage === 5 ? "active-page" : ""
          }`}
          onClick={() => onPageClick(5)}
        ></button>
        <button
          className={`circular-button succ ${
            currentPage === 6 ? "active-page" : ""
          }`}
          onClick={() => onPageClick(6)}
        ></button>
        <button
          className={`circular-button ${
            currentPage === 7 ? "active-page" : ""
          } large`}
          onClick={() => onPageClick(7)}
        >
          buy
        </button>
      </div>
      <div
        className="bottom-left-corner"
        style={{
          fontFamily: "Nimbus Sans Extd D W01 Light",
          fontWeight: "700",
        }}
      >
        <a href="https://www.skyridge.eu/skyridge_privacy.pdf" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a> | 
        <a href="https://www.skyridge.eu/skyridge_imprint.pdf" target="_blank" rel="noopener noreferrer">
          Imprint
        </a> | 
        <a href="https://www.skyridge.eu/skyridge_terms_and_conditions.pdf" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a> | 
        <a href="https://www.skyridge.eu/about_us.pdf" target="_blank" rel="noopener noreferrer">
          About Us
        </a> | 
        <a href="https://discord.gg/taranissnkrs" target="_blank" rel="noopener noreferrer">
          Discord
        </a> | 
        <a href="https://twitter.com/Taranis_SNKRS" target="_blank" rel="noopener noreferrer">
          Twitter
        </a>
      </div>
      <div
        className="bottom-right-corner"
        style={{
          fontFamily: "Nimbus Sans Extd D W01 Light",
          fontWeight: "700",
        }}
      >
        Taranis all rights reserved
      </div>
    </div>
  );
}

export default Menu;
