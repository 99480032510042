import React from "react";
import Lottie from "lottie-react";

function Card({ id, heading, image, footer }) {
  return (
    <div key={id} className="grid-item">
      <div className="item-heading">{heading}</div>
      <div className="item-image">
        <Lottie animationData={image} />
      </div>
      <div className="item-footer">{footer}</div>
    </div>
  );
}

export default Card;
