import React from "react";
import Logo from "../assets/taranis_logo.svg";
import "../styles/Header.css";

function Header() {
  return (
    <nav className="header">
      <a href="/" className="site-title">
        <img src={Logo} alt="Logo" />
      </a>
      <ul>
        <li>
          <a
            className="dashboard"
            href="https://dashboard.taranissnkrs.eu"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dashboard
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
