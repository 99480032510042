import React from "react";
import "../styles/Introduction.css";

function Introduction({ onBuyNowClick }) {
  const slogan = "To start botting N!KE like a god.";

  return (
    <div className="introduction">
      <div className="introduction-title">
        your <br />
        divine <br />
        solution
      </div>
      <div className="slogan">{slogan}</div>
      <div className="purchase">
        <button onClick={onBuyNowClick}>buy now</button>
      </div>
    </div>
  );
}

export default Introduction;
