import React, { useState } from "react";
import Features from "./components/Features";
import Header from "./components/Header";
import Introduction from "./components/Introduction";
import Map from "./components/Map";
import Menu from "./components/Menu";
import Platform from "./components/Platform";
import Pricing from "./components/Pricing";
import Roadmap from "./components/Roadmap";
import Successes from "./components/Successes";

function App() {
  const [currentPage, setCurrentPage] = useState(1);
  const [menuHeading, setMenuHeading] = useState("navigate the site");

  const handleMenuClick = (page) => {
    setCurrentPage(page);

    switch (page) {
      case 2:
        setMenuHeading("features");
        break;
      case 3:
        setMenuHeading("interfaces");
        break;
      case 4:
        setMenuHeading("regions");
        break;
      case 5:
        setMenuHeading("roadmap");
        break;
      case 6:
        setMenuHeading("successes");
        break;
      case 7:
        setMenuHeading("buy now");
        break;
      default:
        setMenuHeading("navigate the site");
    }
  };

  const handleBuyNowClick = () => {
    setCurrentPage(7);
    setMenuHeading("buy now");
  };

  return (
    <>
      <div className="background-gradient">
        <Header />
        {currentPage === 1 && (
          <Introduction onBuyNowClick={handleBuyNowClick} />
        )}
      </div>
      <div className="background-basic">
        {currentPage === 2 && <Features />}
        {currentPage === 3 && <Platform />}
        {currentPage === 4 && <Map />}
        {currentPage === 5 && <Roadmap />}
        {currentPage === 6 && <Successes />}
      </div>
      <div className="background-lightning">
        {currentPage === 7 && <Pricing />}
      </div>
      <Menu
        onPageClick={handleMenuClick}
        currentPage={currentPage}
        menuHeading={menuHeading}
      />{" "}
    </>
  );
}

export default App;
