import React from "react";

function ExpCard({ image, footer }) {
  return (
    <div className="exp-card">
      <div className="exp-card-img">
        <img src={image} alt="" />
      </div>
      <div className="exp-card-footer">{footer}</div>
    </div>
  );
}

export default ExpCard;
