import React, { useState } from "react";
import "../styles/Pricing.css";
import Plus from "../assets/plus.svg";

function Pricing() {
  const monthlyPrice = {
    initial: 0,
    additional: 29.99,
  };
  const quarterlyPrice = {
    initial: 0,
    additional: 79.99,
  };
  const [activeButton, setActiveButton] = useState("monthly");

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const prices = activeButton === "monthly" ? monthlyPrice : quarterlyPrice;
  const frequencyText =
    activeButton === "quarterly" ? "every 3 months" : "each month";

  return (
    <div className="pricing">
      <div className="pricing-heading">all this within two pricing options</div>
      <div className="pricing-buttons">
        <div className="monthly">
          <button
            className={`${activeButton === "monthly" ? "active" : ""}`}
            onClick={() => handleButtonClick("monthly")}
          >
            monthly
          </button>
        </div>
        <div className="quarterly">
          <button
            className={`${activeButton === "quarterly" ? "active" : ""}`}
            onClick={() => handleButtonClick("quarterly")}
          >
            3 months
          </button>
        </div>
      </div>
      <div className="pricing-layer">
        <div className="pricing-costs">
          <div className="initial">
            <div className="price">{prices.initial}</div>
            <div className="price-desc">initial (VAT incl)</div>
          </div>
          <div className="cross">
            <img src={Plus} alt="+" />
          </div>
          <div className="each-month">
            <div className="price">
              <div className="price">{prices.additional}</div>
            </div>
            <div className="price-desc">{frequencyText}</div>
          </div>
        </div>
      </div>
      <div className="pricing-purchase sold-out">
        <button>sold out</button>
      </div>
      {/*
      <div
        className={
          activeButton === "monthly"
            ? "pricing-purchase"
            : "pricing-purchase sold-out"
        }
      >
        {activeButton === "monthly" ? (
          <a
            href="https://whop.com/taranis/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>buy now</button>
          </a>
        ) : (
          <button>sold out</button>
        )}
      </div>
      */}
    </div>
  );
}

export default Pricing;
